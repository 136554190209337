<template>
  <div class="page">
    <SuccessAndFail
      :msjEnable.sync="msjEnable"
      :mensajeDialog="mensaje"
      :buttomAceptar="buttomAceptar"
      :buttomCancelar="buttomCancelar"
      :icon.sync="iconModal"
      @Aceptar="ClickAceptar"
      :aceptar="aceptarLabel"
      :cancelar="cancelarLabel"
    />
    <div class="grid align-items-center vertical-container">
      <div
        class="feature-intro"
        v-if="
          resumen.formType.includes('Sumario') ||
          resumen.formType == 'oficio' ||
          resumen.formType == 'denuncia'
        "
      >
        <h1>Detalles</h1>
        <p test-id="titleSumario">Diligencias de Sumario</p>
      </div>
      <div
        v-if="
          resumen.formType.includes('Expediente') || resumen.formType == 'contravencional-oficio'
        "
        class="feature-intro"
      >
        <h1>Detalles</h1>
        <p test-id="titleExpediente">Diligencias de Expediente</p>
      </div>
      <div
        v-if="
          resumen.formType.includes('Unidades Fiscales de Investigacion') ||
          resumen.formType.includes('UFI')
        "
        class="feature-intro"
      >
        <h1>Detalles</h1>
        <p test-id="titleExpediente">Diligencias de UFI</p>
      </div>
      <!-- eliminar Actuacion -->
      <Button
        v-if="
          isOnlineActuacion() &&
          authorizedUserActuacion(['Super User', 'Jefe de Dependencia', 'Jefe de Sumario'])
        "
        v-tooltip.left="'Eliminar esta Actuacion'"
        icon="pi pi-trash"
        class="p-button-rounded p-button-outlined p-button-danger mr-2 ml-2"
        id="boton-eliminar"
        @click="deleteActuacion()"
        test-id="buttonEliminar"
      />
      <Tag
        test-id="tagEstadoSumario"
        :severity="colorActive()"
        :value="sumario.sumarioEstado"
        style="font-size: 20px"
        class="ml-2"
      ></Tag>
      <div class="ml-auto flex flex-column">
        <Button
          v-if="
            isOnlineActuacion() && authorizedUserActuacion(['Super User','Jefe de Dependencia','Jefe de Sumario', 'Oficial Sumariante'].concat(prosecutorUserTypes)) &&
            sumario.sumarioEstado !== 'Finalizada'
          "
          v-tooltip.left="'Editar esta actuación'"
          icon="pi pi-pencil"
          class="p-button-help w-10rem ml-auto"
          @click="goToActuationEditMode()"
          :disabled="loading"
        >
          <i class="pi pi-pencil mr-2" /> Editar Actuación
        </Button>
        <Button 
          label="Warning" 
          class="p-button-warning mt-3 w-8rem ml-auto" 
          @click="openModalTemplate('ModalRefEvidence')" 
          v-if="isForensicOrCriminology"
          :disabled="loading"
        >
          <p class="text-3xl mr-1">+</p>
          Evidencia
        </Button>
        <a :href="ensureHTTP(evidence)" target="_blank" v-else>
          <Button 
            icon="pi pi-folder-open" 
            class="p-button-success mt-3 w-8rem ml-auto" 
            label="Evidencia"
            :disabled="evidence === ''"
            v-tooltip.left="evidence === '' ? 'Todavía no se ha agregado evidencia' : 'Ver evidencia'"
            @click="copyToClipboard()" 
          />
        </a>
      </div>
    </div>

    <div class="grid">
      <div class="flex justify-content-between w100 detalleSumario">
        <div class="md:col-4">
          <div v-if="showMo()">
            <h4>Modus Operandi</h4>
            <!-- <p>{{response[0].ModusoperandiNombre}}</p> -->
            <p>{{ resumen.modusOperandi.Nombre || resumen.modusOperandi }}</p>
            <!-- con este formato se tra del store, pero se borra al actualizar pag-->
          </div>

          <div v-if="showMo()">
            <h4
              v-if="
                !resumen.formType.includes('Unidades Fiscales de Investigacion') ||
                resumen.formType.includes('UFI')
              "
            >
              Causa/Caratula
            </h4>
            <h4 v-else>Delito</h4>
            <div class="flex flex-wrap" test-id="causaCaratulaDiv">
              <p test-id="causaCaratula" v-if="resumen.caratula || resumen.listaCaratulas">
                {{ resumen.listaCaratulas ? resumen.listaCaratulas : resumen.caratula }}
              </p>
              <p v-else test-id="causaCaratula" style="color: red">Sin Causa/Caratula asignada</p>
              <Button icon="pi pi-pencil"  v-if="isOnlineActuacion() &&  authorizedUserActuacion(['Super User', 'SecretarioActual', 'Jefe de Sumario'].concat(prosecutorUserTypes))" class="p-button-rounded p-button-info p-button-text edit-pencil" v-tooltip.bottom="'Cambiar por…'" @click="openModal('Caratula')"/>
            </div>
            <!-- <p>{{resumen.caratula.nombre || resumen.caratula}}</p> -->
          </div>
          <div v-if="!showMo()">
            <h4>Carátula del expediente</h4>
            <div class="flex flex-wrap" test-id="caratulaExpediente">
              <p test-id="caratulaExp" :style="[{'color': resumen.listaArticulos  == '' ? 'red' : 'rgb(44, 62, 80)'}]">{{resumen.listaArticulos != '' ? resumen.listaArticulos  :  'Sin Caratula Asignada'}}</p>
              <Button icon="pi pi-pencil" v-if="isOnlineActuacion() && authorizedUserActuacion(usersAllowedToEdit)" class="p-button-rounded p-button-info p-button-text edit-pencil" v-tooltip.bottom="'Cambiar por…'" @click="openModal('Caratula')" />
            </div>
            <!-- <p>{{resumen.caratula.nombre || resumen.caratula}}</p> -->
          </div>

          <BlockUI :blocked="modalSpinner" :fullScreen="true">
            <Dialog
              :header="'Editar ' + modalAttributes.type"
              :visible="displayModal"
              :style="{ width: '500px' }"
              :contentStyle="{ maxHeight: '500px' }"
              :modal="true"
              :closable="false"
              ref="modalUpdate"
            >
              <div class="field" v-if="modalAttributes.showMo && !confirmationMode">
                <p class="m-0">Seleccione {{ modalAttributes.new }} {{ modalAttributes.type }}</p>
                <div class="field" :test-id="modalAttributes.divTestId">
                  <Dropdown
                    v-if="modalAttributes.form === 'Dropdown'"
                    :dataKey="modalAttributes.dataKey"
                    :placeholder="modalAttributes.type"
                    v-model="selectedDropdownValue"
                    @change="
                      changeModal([$event, modalAttributes.vModel, 'ModalDropdown']);
                      selectedDropdownValue = $event.value;
                    "
                    :options="modalAttributes.optionsList"
                    :optionLabel="modalAttributes.optionLabel"
                    :test-id="modalAttributes.testId"
                    :filter="true"
                    ref="ModalDropdown"
                  />
                  <InputText
                    v-if="modalAttributes.form === 'InputText'"
                    type="text"
                    :placeholder="modalAttributes.type"
                    @change="changeModal([$event, modalAttributes.vModel, 'ModalInputText'])"
                    :value="this[modalAttributes.vModel]"
                    :test-id="modalAttributes.testId"
                    ref="ModalInputText"
                  />
                </div>
                <div>
                  <div
                    class="field containerArticulos"
                    v-if="this[modalAttributes.arrayItems.arrayName].length > 0"
                  >
                    <div
                      class="listaArticulos"
                      v-for="item in this[modalAttributes.arrayItems.arrayName]"
                      :key="item[modalAttributes.arrayItems.id]"
                    >
                      <div class="articulo__nombre">
                        <p>
                          {{ modalAttributes.arrayItems.attribute1.text }}
                          {{ item[modalAttributes.arrayItems.attribute1.prop] }}
                          {{ modalAttributes.arrayItems.attribute2.text }}
                          {{ item[modalAttributes.arrayItems.attribute2.prop] }}
                        </p>
                      </div>
                      <div class="btn">
                        <Button
                          icon="pi pi-trash"
                          class="md:col-4 p-button-text"
                          @click="
                            isOnlineActuacion() &&
                              deleteModalItem(item[modalAttributes.arrayItems.id])
                          "
                          style="
                            color: white;
                            width: 30px;
                            height: 30px;
                            background-color: #dc3545;
                            margin: 3px;
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="confirmationMode">
                <span
                  >¿Está seguro que desea realizar un cambio de {{ modalAttributes.type }}?</span
                >
              </div>
              <template #footer>
                <div v-if="isOnlineActuacion() && !confirmationMode">
                  <div test-id="cancelUpdate">
                    <Button
                      label="Cancelar"
                      icon="pi pi-times"
                      @click="closeModal"
                      class="p-button-text"
                    />
                  </div>
                  <div test-id="saveUpdate">
                    <Button
                      label="Guardar"
                      icon="pi pi-check"
                      @click="saveModalData"
                      autofocus
                      v-if="!modalSpinner"
                      :disabled="isSaveDisabled()"
                    />
                  </div>
                </div>
                <div v-if="isOnlineActuacion() && confirmationMode">
                  <div test-id="cancelUpdate">
                    <Button
                      label="No"
                      icon="pi pi-times"
                      @click="closeModal"
                      class="p-button-text"
                    />
                  </div>
                  <div test-id="saveUpdate">
                    <Button label="Sí" icon="pi pi-check" @click="confirmationMode = false" />
                  </div>
                </div>
                <Button :disabled="true" v-if="modalSpinner">
                  <i class="pi pi-spin pi-spinner mr-2" style="font-size: 1rem"></i>
                  Guardando...
                </Button>
                <!-- <ProgressSpinner :class="{'spinner':timer, 'ml-6':true, 'mt-auto':true }" style="width:40px;height:40px" strokeWidth="5" fill="#EEEEEE" animationDuration="1.5s"/> -->
              </template>
            </Dialog>
          </BlockUI>
          <div v-if="resumen.formType.includes('Preliminar')">
            <h4>Interviniente</h4>
            <div class="flex flex-wrap" test-id="juzgadoDiv">
              <!-- <p>Juzgado: {{sumario.causaJuzgado.length}}</p> -->
              <p v-if="typeof(resumen.juzgado) === 'object'" test-id="juzgado" >{{resumen.juzgado.Nombre}}</p>
              <p v-else test-id="juzgado">{{sumario?.causaJuzgado.length == 0 ? resumen.dependencia: resumen.juzgado}} </p>
              <Button icon="pi pi-pencil" v-if="authorizedUserActuacion(usersAllowedToEdit) && isOnlineActuacion()" class="p-button-rounded p-button-info p-button-text edit-pencil" v-tooltip.bottom="'Cambiar por…'" @click="openModal('Interviniente')"/>
            </div>
            <!-- <p>{{resumen.juzgado.nombre || resumen.juzgado}}</p> -->
          </div>
          <div v-else-if="resumen.formType.includes('Unidades Fiscales')" >
            <h4>UFI N°</h4>
            <div class="flex flex-wrap" test-id="numeroUfiDiv">
              <!-- <p v-if="(typeof(resumen.SumarioUfi) != undefined)" test-id="numeroUfi" >{{resumen.SumarioUfi}}</p> -->
              <p test-id="numeroUfi" :style="[{'color': (typeof(resumen.SumarioUfi) == undefined) ? 'red' : 'rgb(44, 62, 80)'}]">{{(typeof(resumen.SumarioUfi) == undefined) ? 'Sin N° Ufi asignado': resumen.SumarioUfi}} </p>
              <Button icon="pi pi-pencil" v-if="authorizedUserActuacion(usersAllowedToEdit) && isOnlineActuacion()" class="p-button-rounded p-button-info p-button-text edit-pencil" v-tooltip.bottom="'Cambiar por…'" @click="openModal('Ufi Interviniente')"/>
            </div>
            <h4>Fiscal a Cargo</h4>
            <div class="flex flex-wrap" test-id="prosecutorDiv">
              <!-- <p v-if="resumen.prosecutor" test-id="prosecutor" >{{resumen.prosecutor}}</p> -->
              <p test-id="prosecutor" :style="[{'color': (typeof(resumen.prosecutor) == undefined) ? 'red' : 'rgb(44, 62, 80)'}]">{{(typeof(resumen.prosecutor) == undefined)? 'Sin Fiscal asignado': resumen.prosecutor}} </p>
              <Button icon="pi pi-pencil" v-if="authorizedUserActuacion(usersAllowedToEdit) && isOnlineActuacion()" class="p-button-rounded p-button-info p-button-text edit-pencil" v-tooltip.bottom="'Cambiar por…'" @click="openModal('Fiscal')"/>
            </div>      
            <h4>Ayudante Fiscal</h4>
            <div class="flex flex-wrap" test-id="assistantDiv">
              <!-- <p v-if="resumen.assistant" test-id="assistant" >{{resumen.assistant}}</p> -->
              <p test-id="assistant" :style="[{'color': (typeof(resumen.assistant) == undefined) ? 'red' : 'rgb(44, 62, 80)'}]">{{(typeof(resumen.assistant) == undefined)? 'Sin Ayudante asignado': resumen.assistant}} </p>
              <Button icon="pi pi-pencil" v-if="authorizedUserActuacion(['Super User', 'SecretarioActual', 'Jefe de Sumario', 'Jefe de Dependencia']) && isOnlineActuacion()" class="p-button-rounded p-button-info p-button-text edit-pencil" v-tooltip.bottom="'Cambiar por…'" @click="openModal('Ayudante Fiscal')"/>
            </div>                    
            <!-- <p>{{resumen.juzgado.nombre || resumen.juzgado}}</p> -->
          </div>
          <div v-else>
            <h4>Juzgado Interviniente</h4>
            <div class="flex flex-wrap" test-id="juzgadoDiv">
              <p v-if="typeof(resumen.juzgado) === 'object'" test-id="juzgado" >{{resumen.juzgado.Nombre}}</p>
              <p v-else test-id="juzgado" :style="[{'color': resumen.juzgado == '' ? 'red' : 'rgb(44, 62, 80)'}]">{{resumen.juzgado == '' ? 'Sin Juzgado asignado': resumen.juzgado}} </p>
              <Button icon="pi pi-pencil" v-if="authorizedUserActuacion(usersAllowedToEdit) && isOnlineActuacion()" class="p-button-rounded p-button-info p-button-text edit-pencil" v-tooltip.bottom="'Cambiar por…'" @click="openModal('Juzgado interviniente')"/>
            </div>
          </div>

          <div>
            <h4>Iniciado</h4>
            <!-- <p>{{response[0].SumarioFechaCreacion}}</p> -->
            <p test-id="fechInicio">
              {{ resumen.fechaDeExtencionCertificadoText[0] }} {{ resumen.horaDeExtCertificado }} hs
            </p>
          </div>
          <div :style="{ display: 'flex', flexDirection: 'row' }">
            <div style="margin-right: 10px">
              <h4>N° de Actuacion</h4>
              <!-- <p>{{response[0].SumarioFechaCreacion}}</p> -->
              <div class="flex flex-wrap" test-id="NActuacionDiv" >
                <p test-id="NActuacion">{{sumario.causaNumero}}</p>
                <Button icon="pi pi-pencil"  v-if="sumario.causaNumero && authorizedUserActuacion(usersAllowedToEdit) && isOnlineActuacion()" class="p-button-rounded p-button-info p-button-text edit-pencil" v-tooltip.bottom="'Cambiar por…'" @click="openModal('N° de Actuación')"/>
              </div>
            </div>
            <div v-if="!sumario.causaNumero">
              <h4>N° de Extracto</h4>
              <div class="flex flex-wrap" test-id="NActuacionDiv">
                <p test-id="NActuacion">
                  {{
                    sumario["ActuacionUsuarioDependencia"]
                      ? sumario.ActuacionUsuarioDependencia.find((e) => e.Fechahasta === null)
                          .Numeroextracto
                      : ""
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="md:col-4">
          <div>
            <h4>Lugar del hecho</h4>
            <!-- <p>{{"Calle "+ response[0].HechoubicacionCalle +" Numero "+response[0].HechoubicacionNumero + " Departamento "+ response[0].HechoubicacionDepartamento}}</p> -->
            <p test-id="ubicacion">{{ resumen.lugarDelHecho }}</p>
          </div>

          <div>
            <h4>Fecha del Hecho</h4>
            <!-- <p>{{response[0].fechaDelHecho}}</p> -->
            <p v-if="typeof resumen.fechaDelHechoText != 'undefined'" test-id="fechaHecho">
              {{ resumen.fechaDelHechoText[0] }}
            </p>
          </div>
          <div>
            <h4>Hora aproximada</h4>
            <p test-id="horaAprox">{{ resumen.horaDelHecho }}</p>
          </div>
          <div>
            <h4
              v-if="
                includesWords([
                  'Incendio de Automotor',
                  'Preliminar por Siniestro Vial',
                  'Preliminar por Caida Casual',
                ])
              "
            >
              Protagonista/Acompañante/Damnificado
            </h4>
            <h4 v-else-if="includesWords(['Unidades Fiscales de Investigacion'])">
              Damnificado/Victima/Fallecido
            </h4>
            <h4 v-else>Damnificado/Denunciante</h4>
            <ul>
              <li v-for="(item, i) in sumario.denunciante.list" v-bind:key="i">
                <div v-if="item.doc === undefined" test-id="denunciante">
                  {{ apellidoNombre(item.Apellido, item.Nombre) }}
                </div>
                <div v-else-if="item.ordenPublico == true" test-id="denunciante">
                  {{ "Orden Público" }}
                </div>
                <div v-else-if="item.doc.apellido != undefined" test-id="denunciante">
                  {{ item.doc.apellido + ", " + item.doc.nombre }}
                </div>
              </li>
            </ul>
            <div class="sindato" v-if="sumario.denunciante.list.length < 1" test-id="denunciante">
              Sin datos
            </div>
          </div>

          <div>
            <h4 v-if="includesWords(['Establecer Paradero'])">Extraviados</h4>
            <h4 v-else-if="includesWords(['Actuacion Preliminar por Tentativa de Suicidio'])">Autolesionados</h4>
            <h4 v-else-if="includesWords(['D.E Siniestro Vial', 'Flagrancia', 'CAVIG', 'ANIVI','Genéricos',' Delitos Contra la Propiedad','Estafas y Delitos Informáticos', 'Delitos Informáticos y Estafas'])">Acusados/Aprehendidos</h4>
            <h4 v-else-if="includesWords(['Expediente Contravencional','Sumario Prevencional', 'Preliminar por Fuga','Preliminar para Establecer','Preliminar por Incendio en Vivienda', 'Preliminar - Otros'])">Acusados/Detenidos</h4>
            <h4 v-else></h4>
            <ul>
              <li v-for="(item, i) in sumario.acusados.list" v-bind:key="i">
                <!-- En este caso los datos vienen de la Api rest -->
                <div v-if="item.doc === undefined" test-id="acusado">
                  {{ apellidoNombre(item.Apellido, item.Nombre) }}
                </div>
                <!-- En este otro caso los dato provienen de la carga del Front -->
                <div v-else-if="item.doc.apellido != undefined" test-id="acusado">
                  {{ apellidoNombre(item.doc.apellido, item.doc.nombre) }}
                </div>
                <!-- i debe ser != a lengt -1 ya que en el store devulve un acusado de mas -->
                <div
                  v-else-if="item.type == 'empty' && i != sumario.acusados.list.length - 1"
                  test-id="acusado"
                >
                  {{ "Persona de Filiación desconocida" }}
                </div>
                <div
                  v-else-if="
                    item.filiacionDesconocida == true && i != sumario.acusados.list.length - 1
                  "
                  test-id="acusado"
                >
                  {{ "Persona de Filiación desconocida" }}
                </div>
              </li>
            </ul>

            <div
              class="sindato"
              v-if="
                sumario.acusados.list.length < 1 &&
                !includesWords([
                  'D.E Emergencias Medicas',
                  'D.E Ahorcamiento o Suicidio',
                  'D.E Intoxicacion',
                  'D.E Incendio en Vivienda',
                  'Preliminar por Incendio de Automotor',
                  'Preliminar por Siniestro Vial',
                  'Preliminar por Caida Casual',
                ])
              "
              test-id="acusado"
            >
              Sin datos
            </div>
          </div>
        </div>
        <div class="md:col-4">
          <div v-if="isOnlineActuacion()">
            <h4>Secretario Actual</h4>
            <!-- <p v-if="sumario.ActuacionUsuarioDependencia.find(e=>e.Fechahasta===null).Apellido != 'Persona de Filiacion desconocida'" test-id="secretariaAct">{{sumario.ActuacionUsuarioDependencia.find(e=>e.Fechahasta===null).Nombre+", "+sumario.ActuacionUsuarioDependencia.find(e=>e.Fechahasta===null).Apellido}}</p>
            <p v-else class="danger__text">NO está asignado ningun secretario</p> -->
            <p :class="{ danger__text: dangerClass }" test-id="secretarioAct">
              {{ secretarioActualString() }}
            </p>
          </div>

          <div>
            <h4>Secretario Original</h4>
            <p test-id="secretarioOrg">{{ secretarioOriginalString() }}</p>
            <!-- <p v-else class="danger__text">NO está asignado ningun secretario</p> -->
          </div>

          <div>
            <h4>Dependencia Policial</h4>
            <p test-id="dependenciaExp">{{ resumen.dependencia }}</p>
            <!-- || user.dependencia}}</p> -->
          </div>
        </div>
      </div>
      <div class="selectorOption">
        <div>
          <CustomAutoComplete
            :dropdown="true"
            :suggestions="filteredDependencia"
            @complete="filterDependencia($event)"
            :class="selectorDisabled ? 'hiddenSelector' : 'enabledSelector'"
            field="dependencia"
            modelValue="id"
            placeholder="Dependencia"
            test-id="DependenciaInput"
            v-model="dataDependencia"
          >
            <template #item="slotProps">
              <div class="location-item">
                <div :test-id="'seleccion-' + slotProps.item.dependencia" class="ml-2">
                  {{ slotProps.item.dependencia }}
                </div>
              </div>
            </template>
          </CustomAutoComplete>
          <div v-if="isDisabled.btnEnviar">
            <div class="preloader">
              <ProgressSpinner />
            </div>
          </div>
          <Button
            @click="btnEnvio"
            v-else-if="isOnlineActuacion() && sumario['actuacionUsuarioDependenciaID']!= undefined && authorizedUserActuacion(usersAllowedToEdit)"
            test-id="btn-enviar"
            :class="
              selectorDisabled // conditional
                ? 'p-button-danger' // starting properties
                : 'p-button-success'
            "
            icon="pi pi-send"
            :label="selectorDisabled // conditional
            ? ('Enviar a otra dependencia') // starting properties
            :('Enviar definitivamente')"
            :disabled="dataDependencia == '' && !selectorDisabled" 
          />

          <Dialog
            header="Confirmación"
            :visible.sync="displayConfirmation"
            :containerStyle="{ width: '400px' }"
            :modal="true"
          >
            <div class="confirmation-content" test-id="modal-body">
              <i class="pi pi-question-circle mr-3" style="font-size: 4rem; color: red" />
              <span>¿Está seguro que desea enviar esta actuación a otra dependencia?</span>
            </div>
            <template #footer>
              <Button
                label="No"
                icon="pi pi-times"
                @click="closeConfirmation"
                class="p-button-text"
                autofocus
                test-id="btn-no"
              />
              <Button
                label="Sí"
                icon="pi pi-check"
                @click="changeSelectorStatus"
                class="p-button-danger"
                test-id="btn-si"
              />
            </template>
          </Dialog>
        </div>
      </div>
      <ModalTemplate
        title="Confirmación"
        type="confirmativo"
        message="¿El hecho fue esclarecido?"
        :showIcon="true"
        @cancel="updateStateActuacion()"
        @confirm="confirm('ModalRefConfirm')"
        :dismissableMask="true"
        severity="primary"
        ref="ModalRefConfirm"
      />
      <ModalTemplate
        title="Información"
        type="informativo"
        message="Debe cargar al menos 3 diligencias para finalizar la actuación"
        :showIcon="true"
        @confirm="closeModalTemplate('ModalRefInform')"
        severity="danger"
        ref="ModalRefInform"
      />
      <ModalTemplate 
        title="Agregar Evidencia"
        type="proceso"
        :modal="true"
        @cancel="$refs.ModalRefEvidence.close()"
        @confirm="saveEvidenceAndSendEmail()"
        severity="warning"
        ref="ModalRefEvidence"
      >
      <div class="flex w-9">
        <div>
          <i class="pi pi-link text-7xl text-yellow-500"></i>
        </div>
        <div>
          <InputText
            id="evidence"
            type="text"
            placeholder="Ingresar evidencia..."
            v-model="evidence"
            test-id="evidence"
            class="ml-3 mt-3 w-23rem"
          />
        </div>
      </div>
      </ModalTemplate>
      <div class="table-diligence w100 md:col-12">
        <div class="list">
          <DataTable
            ref="doubleEllipsisIcon"
            :value="filesAndDiligences"
            :loading="loading"
            test-id="diligenciasTable"
            @row-reorder="onRowReorder"
            responsiveLayout="scroll"
          >
            <div id="double-ellipsis-icon-id">
              <Column
                :rowReorder="orderBy.nombre !== 'Fecha' && !sumario.spinnerOrderTablePrintFrom"
                headerStyle="width: 3rem"
                :reorderableColumn="false"
                rowReorderIcon="double-ellipsis-icon"
              />
            </div>
            <template #header>
              <div class="table-header flex justify-content-between align-items-center">
                <h2 test-id="diligencesFilesTitle">Gestión de diligencias y archivos</h2>
                <div style="margin-top: -25px">
                  <p style="margin-left: 7px">Ordenar Por:</p>
                  <Dropdown
                    test-id="orderByDropDown"
                    style="margin-top: 1px"
                    class="input-order lg:col-5 md:col-7 sm:col-12"
                    v-model="orderBy"
                    :options="orderList"
                    optionLabel="nombre"
                    placeholder="Orden"
                  />
                </div>
                <div test-id="saveOrderButton">
                  <Button
                    type="button"
                    v-if="orderBy.nombre === 'Impresión'"
                    class="p-button-success mr-2 mt-buttons"
                    @click="updateOrderTableDataBase"
                    :disabled="sumario.spinnerOrderTablePrintFrom"
                    >{{
                      !sumario.spinnerOrderTablePrintFrom
                        ? "Guardar orden de impresión"
                        : "Guardando..."
                    }}</Button
                  >
                </div>
                <!-- <div v-if="sumario.spinnerOrderTablePrintFrom"> 
                  <ProgressSpinner style="width: 50px" />
                  <p  style="margin-top:  -15px ; margin-left: 12px">
                    Guardando orden de impresión...
                  </p>
                </div> -->
                <div test-id="addDiligenceButton">
                  <Button
                    test-id="buttomAgregarDiligencia"
                    v-if="
                      (isOnlineActuacion() || !$offline.onlineMode) &&
                      sumario.sumarioEstado != 'Finalizada' &&
                      user?.userdata?.claseDeUsuario != 'UsuarioJuzgado'
                    "
                    @click="goToAgregarDiligencia"
                    class="mt-buttons"
                    >Agregar diligencia</Button
                  >
                  <!-- v-if="isOnlineActuacion() || (!$offline.onlineMode && sumario.sumarioEstado != 'Finalizada')"  -->
                </div>
              </div>
            </template>
            <template #empty> Aun no contruyes diligencias. </template>
            <template #loading> Cargando datos. </template>
            <Column field="doc.tipo" header="Tipo">
              <template #body="slotProps">
                {{ slotProps.data.Nombre }}
                <div v-if="slotProps.data.file" style="overflow-wrap: break-word">
                  {{ slotProps.data.Filename }}
                </div>
              </template>
            </Column>
            <Column field="doc.creado" header="Fecha">
              <template #body="slotProps">
                  {{slotProps.data.Fechainivig || fechaCreacion(slotProps.data.FechaCreacion)}}
              </template>
            </Column>
            <Column field="doc.creado" header="Propietario de la diligencia">
              <template #body="slotProps">
                {{  apellidoNombre(slotProps.data.ApellidoP , slotProps.data.NombreP )}}
              </template>
            </Column>  
            <Column  field="doc.creado" header="Tipo de Usuario">
              <template #body="slotProps">
                {{ slotProps.data.TipoUsuario }}
              </template>
            </Column>             
            <Column headerStyle="width: 5em; text-align: right;" bodyStyle="padding-right:8px; text-align: right; ">
              <template #header>
                <!-- <Button type="button" icon="pi pi-cog"></Button> -->
              </template>
              <template
                #body="slotProps"
                class="flex flex-column md:flex-row"
                style="background-color: blue; text-align: right"
              >
                <div class="flex-reverse" style="text-align: right">
                  <!-- imprimir diligencia -->
                  <!-- <Button type="button" class="p-button-outlined" v-tooltip.left="'Imprimir diligencia'" icon="pi pi-print" @click="printAction(slotProps.data.DiligenciaID)" ></Button> -->
                  <!-- abre diligencia -->
                  <Button
                    v-if="!slotProps.data.file && determineActionButtonsVisibility(slotProps)"
                    type="button"
                    v-tooltip.left="'Ver esta diligencia'"
                    icon="pi pi-search"
                    class="p-button-success mr-2"
                    @click="seeDiligencia(slotProps.data.DiligenciaID)"
                  ></Button>
                  <Button
                    v-if="determineEmailButtonVisibility(slotProps.data) && indexEmailButtonDisabled !== slotProps.index"
                    type="button"
                    v-tooltip.left="'Enviar email a la dependencia'"
                    icon="pi pi-envelope"
                    class="mr-2"
                    @click="() => {emailBody = 'emailFileHtml', sendAllMails(slotProps)}"
                  ></Button>
                  <ProgressSpinner v-if="indexEmailButtonDisabled == slotProps.index" style="width: 40px; height: 40px;" />
                  <!-- abre archivo -->
                  <a v-if="slotProps.data.file && determineActionButtonsVisibility(slotProps)" :href="viewFile(slotProps.data)" target="_blank">
                    <Button
                      type="button"
                      v-tooltip.left="'Ver este archivo'"
                      icon="pi pi-search"
                      class="p-button-success mr-2"
                    ></Button>
                  </a>
                  <Button
                    v-if="slotProps.data.file && authorizedUserDiligencia() && determineActionButtonsVisibility(slotProps)"
                    type="button"
                    v-tooltip.left="'Eliminar este archivo'"
                    icon="pi pi-trash"
                    class="p-button-danger mr-2"
                    @click="deleteFile(slotProps.data)"
                    :test-id="'deleteFile' + slotProps.index"
                  ></Button>
                  <!-- editar diligencia -->
                  <Button
                    v-if="
                      !slotProps.data.file &&
                      authorizedUserDiligencia(slotProps.data.UsuarioID) &&
                      sumario.sumarioEstado != 'Finalizada' && 
                      determineActionButtonsVisibility(slotProps)
                    "
                    type="button"
                    v-tooltip.left="'Editar esta diligencia'"
                    icon="pi pi-pencil"
                    class="p-button-warning mr-2"
                    @click="editDiligencia(slotProps.data.DiligenciaID)"
                    test-id="button-editar-diligencia"
                  ></Button>

                  <!-- eliminar diligencia -->
                  <Button
                    v-if="
                      isOnlineActuacion() &&
                      !slotProps.data.file &&
                      authorizedUserDiligencia(slotProps.data.UsuarioID) &&
                      sumario.sumarioEstado != 'Finalizada'&& 
                      determineActionButtonsVisibility(slotProps)
                    "
                    test-id="butonEliminarDiligencia"
                    type="button"
                    v-tooltip.left="'Eliminar esta diligencia'"
                    icon="pi pi-trash"
                    class="p-button-danger mr-2"
                    @click="deleteDiligencia(slotProps.data.DiligenciaID)"
                  ></Button>
                </div>
              </template>
            </Column>
            <template #footer>
              <div
                class="flex flex-column"
                style="align-items: flex-end; margin-right: 2.5%; gap: 5px"
              >
                <div>
                  Total de diligencias: {{ filesAndDiligences ? filesAndDiligences.length : 0 }}
                </div>
                <div>Total de archivos: {{ files ? files.length : 0 }}</div>
              </div>
            </template>
          </DataTable>
          <div class="flex justify-content-end mt-3">
                <Button v-if="isOnlineActuacion() && sumario.sumarioEstado == 'Vencida'" type="button" icon="pi pi-sun" label="Activar Prorroga" class="p-button-secondary mr-3" @click="activarProrroga()"/>
                <Button  
                  test-id="buttonFinalizarInstruccion" 
                  type="button"  
                  label="Finalizar Instrucción" 
                  v-tooltip.left="'Finalizar Actuacion'"  
                  icon="pi pi-link" 
                  class="p-button-warning mr-2" 
                  :disabled ="disabledModifyStatusActuation"
                  v-if="isOnlineActuacion() && authorizedUserActuacion(usersAllowedToEdit) && (sumario.sumarioEstado != 'Finalizada') && secretarioActualString()!='NO está asignado ningun secretario'" 
                  @click="verifyAmountOfDiligences('ModalRefConfirm')">
                </Button>
                <Button test-id="buttonReestablecerInstruccion" type="button"  label="Reestablecer Instrucción" v-tooltip.left="'Reestablecer Actuacion'"  icon="pi pi-link" class="p-button-warning mr-2" 
                v-if="authorizedUserActuacion(usersAllowedToEdit) && (sumario.sumarioEstado == 'Finalizada')" @click="changeStatusActuacion()" :disabled ="disabledModifyStatusActuation"></Button>                
          </div>
          <div v-if="isOnlineActuacion() && user?.userdata?.claseDeUsuario != 'UsuarioJuzgado'">
            <div class="file-title">
              <b>Adjuntar archivo/s</b>
            </div>
            <div>
              <CustomFileUpload
                ref="FileUpload"
                @parentUpdateFiles="(event, clear) => updateFiles(event, clear)"
                @parentMyUploader="myUploader"
                @parentRemoveFiles="RemoveFiles"
                d="userfile[]"
                invalidFileSizeMessage="{0}: tamaño no válido, el archivo no debe pesar más de {1}."
                accept=".jpg, .jpeg, .pdf, .png"
                :auto="true"
                :customUpload="true"
                @uploader="updateFiles"
                :maxFileSize="15000000"
                :multiple="true"
                uploadLabel="Adjuntar"
                chooseLabel="Examinar..."
                class="p-button-sm"
              >
                <template #empty>
                  <p>Examine o arrastre archivos aquí.</p>
                </template>
              </CustomFileUpload>
            </div>
            <p
              v-if="err"
              style="color: #dc3545; margin-top: 20px; font-weight: 550"
              class="ml-1 negative-mt"
            >
              {{ this.err }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomFileUpload from "../../components/custom-fileupload";
import CustomAutoComplete from "../../components/custom-autocomplete/CustomAutoComplete";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import axios from "axios";
import BlockUI from "primevue/blockui";
import { mapState, mapGetters, createLogger } from "vuex";
import auth from "../../mixins/auth";
import dialogMixin from "./DenunciaFormDatosGenerales/mixins/dialogMixin.js";
import commonMethods from "../../mixins/commonMethods.js";
import ModalTemplate from "../../components/ModalTemplate.vue";
import generalFunctions from "../../store/modules/generalFunctions.js";
import { actuacionesConfig } from "@/router/RoutesConfig/actuacionesConfig/actuacionesConfig";
import prosecutorMixin from "../../components/Dashboard/mixins/prosecutorMixin.js";
import 'vue-toast-notification/dist/theme-sugar.css';
import actuacionesFunctions from '@/mixins/actuaciones/actuacionesFunctions.js';

export default {
  components: {
    DataTable,
    Column,
    BlockUI,
    CustomFileUpload,
    ModalTemplate,
    // Registra el componente asíncrono con el nombre que usarás en la plantilla
    // CustomAutoComplete: defineAsyncComponent(() =>
    //   import('../../components/custom-autocomplete/CustomAutoComplete')
    // )
    CustomAutoComplete,
  },
  props: {
    minimal: {
      type: String,
    },
  },
  name: 'DenunciaFormDatosGenerales',
  mixins:[auth, dialogMixin, commonMethods, prosecutorMixin, actuacionesFunctions],
  data() { 
    return {
      indexEmailButtonDisabled: null,
      selectedDropdownValue: null,
      disabledModifyStatusActuation: false,
      dangerClass: false,
      iconModal: {},
      buttomAceptar: { buttom: true, action: true },
      buttomCancelar: { buttom: true, action: false },
      msjEnable: false,
      mensaje: "",
      formData: null,
      response: null, //[],
      err: false,
      files: null,
      filesAndDiligences: [],
      filesAndDiligencesByDate: [],
      loading: false,
      displayModal: false,
      headerModal: "",
      causaJuzgado: "",
      articulos: [],
      causaCaratula: "",
      // modalSpinner: false,
      dependencia: "",
      filteredDependencia: [],
      selectorDisabled: true,
      displayConfirmation: false,
      orderBy: { id: 1, nombre: "Fecha" },
      orderList: [
        { id: 1, nombre: "Fecha" },
        { id: 2, nombre: "Impresión" },
      ],
      filteredOrder: [],
      isDisabled: { btnEnviar: false },
      displayModal: false,
      headerModal: "",
      causaJuzgado: "",
      ufiInterviniente: "",
      // nroActuacion: '',
      articulos: [],
      // blankArray: [],
      listaCaratulas: [],
      causaCaratula: "",
      assistants: "",
      prosecutor: "",
      // modalSpinner: false,
      sumarioEstado: "",
      // confirmationMode: false,
      aceptarLabel: undefined,
      cancelarLabel: undefined,
      nroActuacion: "",
      ClickAceptar: () => {},
      crimes: [],
      // actuacionType: "",
      evidence: '',
      emailBody: '',
    };
  },
  async created() {
    let { id } = this.$route.params;
    this.loading = true;
    id = id.includes("toUpdate") ? id.replace("toUpdate", "") : id;
    setTimeout(async () => {
      await this.$store.dispatch("sumario/setSumario", id);
      await this.setDiligencias();
      await this.filesQuery(id);
      await this.sortByDate();
      this.loading = false;
    }, 100);
    await this.$store.dispatch("siis/setDependencias");
    this.$store.dispatch("siis/setArticulos");
    this.$store.dispatch("siis/setJuzgados");
    this.$store.dispatch("siis/setCausaCaratula");
    this.$store.dispatch("siis/setDelitos");
    this.$store.dispatch("siis/setUFIs");
    this.$store.dispatch("siis/setCausaCaratula");
    this.$store.dispatch("siis/setProsecutorOrAssistant", { en: "prosecutors", es: "fiscal" });
    this.$store.dispatch("siis/setProsecutorOrAssistant", { en: "assistants", es: "ayudante" });
  },
  async mounted() {
    this.getDefaultEvidence();
    // setTimeout(() => {
    //   this.sortByDate();
    // }, 4000);
  },
  computed: {
    ...mapGetters({
      dependenciaList: "siis/getDependecias",
    }),
    ...mapState({
      sumario: (state) => state.sumario,
      user: (state) => state.user,
      juzgadosListFromDB: (state) => state.siis.juzgados,
      articulosList: (state) => state.siis.articulos,
      causasList: (state) => state.siis.causaCaratula,
      delitoList: (state) => state.siis.delitos,
      numeroUfiList: (state) => state.siis.ufis,
      ayudanteFiscal: (state) => state.siis.assistants,
      fiscal: (state) => state.siis.prosecutors,
      viewData: (state) => state.sumarioViews,
      formType: (state) => state.sumario.formType,
    }),
    // isSaveDisabled() {//Este computed fue movido a dialogMixin.js como un metodo, devido que al estar aqui una vez que el boton se invabilitaba, quedaba inabilitado para todos los elemntos que lo usaban (N° de Actuacion, Juzgado, N° Ufi, etc)
    //   console.log("this.selectedDropdownValue",this.selectedDropdownValue)
    //   console.log("this.allowEmptySave: ",this.modalAttributes.allowEmptySave)
    //   if (this.modalAttributes.allowEmptySave) {
    //     console.log("entro al if")
    //    return false; // Not disabled if it's allowed to save empty
    //   }
    // // Disable the button if the dropdown value is not selected.
    // return !this.selectedDropdownValue;

    // },
    juzgadosList() {
      if (this.sumario.formType.includes("Preliminar")) {
        return [
          {
            Nombre: this.resumen.dependencia,
          },
          ...this.juzgadosListFromDB,
        ];
      } else {
        return this.juzgadosListFromDB;
      }
    },
    dataNroActuacion: {
      get() {
        // console.log("dataNroActuacion")
        // this.nroActuacion = this.sumario.causaNumero
        return this.sumario.causaNumero;
      },
      // set(value){
      //   return value
      // }
    },
    resumen: {
      get() {
        return this.$store.getters["sumario/dataResumen"];
      },
    },
    dataModusOperandi: {
      get() {
        return this.sumario.modusOperandi.nombre;
      },
    },
    dataCausaCaratula: {
      get() {
        return this.sumario.causaCaratula.nombre;
      },
    },
    // dataJuzgado: {
    //   get () { return this.sumario.causaJuzgado },
    // },
    dataIniciado: {
      get() {
        return this.sumario.fechaCertificadoDenuncia;
      },
    },
    dataLugar: {
      get() {
        return `${this.sumario.preUbicacion.calle} ${this.sumario.preUbicacion.numero}, ${this.sumario.preUbicacion.departamento.nombre}`;
      },
    },
    // dataSecretarioActual: {
    //   get () { return this.sumario.secretarioActual },
    // },
    // dataSecretarioOriginal: {

    //   get () { return this.sumario.secretarioOriginal },
    // },
    dataDependenciaPolicial: {
      get() {
        return this.sumario.dependenciaPolicial;
      },
    },
    dataDependencia: {
      get() {
        return this.dependencia;
      },
      set(value) {
        return (this.dependencia = value);
      },
    },
    denunciantes: {
      get() {
        return this.$store.getters["sumario/denunciante/denuncianteTodosResumen"];
      },
    },
    acusadosTodosResumen: {
      get() {
        return this.$store.getters["sumario/acusados/acusadosTodosResumen"];
      },
    },
    dataArticulos: {
      get() {
        return "";
      },
      set(value) {
        // if (typeof value == 'string') {
        //   this.articulos = value
        //   return
        // }
        if (
          this.$refs.modalUpdate.$el.getElementsByClassName("p-dialog-content")[0].offsetHeight >=
          326
        )
          this.$refs.modalUpdate.$el.getElementsByClassName("p-dialog-content")[0].style.overflow =
            "auto";
        if (value !== null && value !== undefined && !this.articulos.includes(value)) {
          this.articulos = [...this.articulos, value];
          // console.log(this.articulos)
        }
      },
    },
    dataCaratulas: {
      get() {
        return "";
      },
      set(value) {
        // if (typeof value == 'string') {
        //   this.articulos = value
        //   return
        // }
        if (
          this.$refs.modalUpdate.$el.getElementsByClassName("p-dialog-content")[0].offsetHeight >=
          326
        )
          this.$refs.modalUpdate.$el.getElementsByClassName("p-dialog-content")[0].style.overflow =
            "auto";
        if (value !== null && value !== undefined && !this.listaCaratulas.includes(value)) {
          this.listaCaratulas = [...this.listaCaratulas, value];
          // console.log(this.articulos)
        }
      },
    },
    dataCrimes: {
      get() {
        return "";
      },
      set(value) {
        if (
          this.$refs.modalUpdate.$el.getElementsByClassName("p-dialog-content")[0].offsetHeight >=
          326
        )
          this.$refs.modalUpdate.$el.getElementsByClassName("p-dialog-content")[0].style.overflow =
            "auto";
        if (value !== null && value !== undefined && !this.crimes.includes(value)) {
          this.crimes = [...this.crimes, value];
        }
      },
    },
    translateNameFromFormType() {
      return generalFunctions.translateNameFromFormType;
    },
    usersAllowedToEdit(){
      let usersAllowed = ['Super User', 'SecretarioActual', 'Jefe de Sumario', 'Jefe de Dependencia'];
      usersAllowed = usersAllowed.concat(this.prosecutorUserTypes);
      return  usersAllowed;
    },
    isForensicOrCriminology(){
      if(
        this.user.userdata.tipoUsuario === "Dpto.Criminalistica" || 
        this.user.userdata.tipoUsuario === "Lab. Informatico Forense"
	    ) {
        return true;
      } else{
        return false;
      }
    },
    emailFileHtml() {
      return `
        <p>${this.dependencyOwn}, ${this.currentDate}</p>
        <h1>${this.sumario.tiposumarioID}</h1>
        <p>S/D</p>
        <p>Objeto: R/ Informe Técnico</p>
        <p>
          Me dirijo a Ud. a fin de informar en relación a lo solicitado por el Sr. Jefe de 
          ${this.resumen.dependencia}, en nota OFICIO LEGAJO Nº ${this.sumario.causaNumero}
        </p>
        <p>Dando cumplimiento a lo solicitado.</p>
      `;
    },
    emailEvidenceHtml() {
      return `
        <p>${this.dependencyOwn}, ${this.currentDate}</p>
        <h1>${this.sumario.tiposumarioID}</h1>
        <p>S/D</p>
        <p>Objeto: R/ Informe Técnico</p>
        <p>
          Me dirijo a Ud. a fin de informar en relación a lo solicitado por el Sr. Jefe de 
          ${this.resumen.dependencia}, en nota OFICIO LEGAJO Nº ${this.sumario.causaNumero}
        </p>
        <p>
          A continuación podrá acceder a la evidencia: <a href='${this.evidence}'> ${this.evidence}</a>
        </p>
        <p>Dando cumplimiento a lo solicitado.</p>
      `;
    },
    currentDate(){
      let currentDate = new Date();
      let options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
      let formattedDate = currentDate.toLocaleDateString('es-ES', options);
      let parts = formattedDate.split(" de ");
      formattedDate = parts[0] + " de " + parts[1] + " del " + parts[2];
      return formattedDate;
    },
    dependencyOwn(){
      return this.user.userdata.tipoUsuario;
    },
  },
  watch: {
    resumen: function (newSumario, oldSumario) {
      // console.log("resumen change", newSumario, oldSumario)
      if (newSumario.ActuacionID !== oldSumario.ActuacionID) {
        // console.log("this.sumario.diligencia 2>", this.sumario.diligencia )
        this.filesAndDiligences = Array.isArray(this.sumario.diligencia)
          ? this.sumario.diligencia
          : [];
        this.filesQuery();
      }
    },
    orderBy: function (newSumario, oldSumario) {
      console.log('watch');
      if (this.orderBy.nombre === "Fecha") {
        this.sortByDate();
      } else if (
        this.orderBy.nombre === "Impresión" &&
        this.filesAndDiligences.some((item) => item.OrderTablePrint !== null)
      ) {
        this.sortTheOrderPrintNulls();
      } else {
        this.filesAndDiligences = this.filesAndDiligences.sort(this.sortByDate);
      }
    },
    'resumen.ActuacionID': {
      handler(newValue) {
        if(newValue !== null){
          this.getDefaultEvidence();
        }
      },
      deep: true
    }
  },
  methods: {
    activarProrroga() {
      this.mensaje = "Está seguro desea activar la prórroga por 5 Días?";
      this.buttomAceptar = { buttom: true, action: true };
      this.buttomCancelar = { buttom: true, action: false };
      this.aceptarLabel = "si";
      this.cancelarLabel = "no";
      this.ClickAceptar = () => {
        this.msjEnable = false;
        this.mensaje = "";
        this.updateStateActuacion(true);
        this.aceptarLabel = undefined;
        this.cancelarLabel = undefined;
      };
      this.msjEnable = true;
    },
    secretarioOriginalString() {
      if (this.sumario.ActuacionUsuarioDependencia == undefined) {
        return this.user.userdata.apellido + ", " + this.user.userdata.nombre;
      } else if (this.sumario.ActuacionUsuarioDependencia[0].info == "Usuario eliminado") {
        return "Los datos de la Persona asociados a este Usuario Fueron borrados";
      } else {
        let min = this.findMin(this.sumario.ActuacionUsuarioDependencia.map((e) => e.Fechadesde));
        let ActuacionUsuarioDependencia = this.sumario.ActuacionUsuarioDependencia.find(
          (e) => e.Fechadesde == min
        );
        return ActuacionUsuarioDependencia.Apellido + ", " + ActuacionUsuarioDependencia.Nombre;
      }
    },
    secretarioActualString() {
      // console.log(">>>>>>>>>>>>>>>>>>>>>: ", this.sumario.ActuacionUsuarioDependencia)
      if (this.sumario.ActuacionUsuarioDependencia == undefined) {
        return this.user.userdata.apellido + ", " + this.user.userdata.nombre;
      } else {
        if (
          this.sumario.ActuacionUsuarioDependencia.find((e) => e.Fechahasta === null) == undefined
        ) {
          this.dangerClass = true;
          return "Los datos de la Persona asociados a este Usuario Fueron borrados";
        }
        if (
          this.sumario.ActuacionUsuarioDependencia.find((e) => e.Fechahasta === null)?.Apellido !=
          "Persona de Filiacion desconocida"
        ) {
          return (
            this.sumario.ActuacionUsuarioDependencia.find((e) => e.Fechahasta === null).Apellido +
            ", " +
            this.sumario.ActuacionUsuarioDependencia.find((e) => e.Fechahasta === null).Nombre
          );
        } else {
          this.dangerClass = true;
          return "NO está asignado ningun secretario";
        }
      }
    },
    isOnlineActuacion() {
      return this.$offline.onlineMode && this.sumario.id?.search("offline-") != 0;
    },
    changeStatusActuacion() {
      this.iconModal = {}; // indicamos que no queremos icono en este modal
      this.buttomAceptar = { buttom: true, action: true };
      this.buttomCancelar = { buttom: true, action: false }; // habilitamos boton para cancelar
      this.ClickAceptar = () => {
        this.msjEnable = false;
        this.mensaje = "";
        this.buttomAceptar = { buttom: true, action: false };
        this.buttomCancelar = { buttom: false, action: false };
        this.updateStateActuacion();
        this.disabledModifyStatusActuation = true;
      };
      this.mensaje =
        this.sumario.sumarioEstado != "Finalizada"
          ? "Seguro que desea dar por Finalizada la Actuacion ?"
          : "Si Reestablece esta Actuacion, la misma volvera a estar 'En Curso' o 'Vencida' segun corresponda, seguro desea continuar?";
      this.msjEnable = true;
    },
    async updateStateActuacion(activarProrroga = false) {
      this.loading = true;
      let newEstadoActuacion;
      if (this.sumario.sumarioEstado == "Finalizada" || activarProrroga) {
        // let fechaActual = new Date();
        // let fechaActualMenos5Dias = new Date(fechaActual.setDate(fechaActual.getDate() -5));
        // newEstadoActuacion = new Date(this.sumario.fechaCertificadoDenuncia) <= fechaActualMenos5Dias ? 'Vencida' : 'En Curso'
        newEstadoActuacion = "En Curso";
      } else {
        newEstadoActuacion = "Finalizada";
      }
      // console.log("fecha de inicio: ",new Date(this.sumario.fechaCertificadoDenuncia))
      // console.log("fehca hace 5 dias: ",fechaActualMenos5Dias)
      // console.log("el sumario tiene mas de 5 dias de? : ",new Date(this.sumario.fechaCertificadoDenuncia) <= fechaActualMenos5Dias )
      // console.log("convertida es: ", new Date(this.sumario.fechaCertificadoDenuncia))
      console.log(newEstadoActuacion);
      let data = {
        ActuacionID: this.sumario.ActuacionID,
        //Si el estado actual es distinto a Finalizada, entonces el Usuario esta finalizando la Actuacion (solo tenemos esa opcion al dia 08-06-2022)
        NuevoEstado: newEstadoActuacion,
        Esclarecido: false,
      };
      try {
        let request = await axios.put(
          `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/estado-actuacion/${this.sumario.id}`,
          data,
          { withCredentials: true, params: { method: "own" } }
        );
        if (request.data.error === "") {
          let iconModal = {
            type: "pi pi-check-circle",
            style: "font-size: 3em; color: rgb(89, 150, 29);",
          };
          if (activarProrroga) {
            this.finalizarCambioEstado(
              "Se activó la prórroga con éxito",
              iconModal,
              true,
              false,
              this.$route.fullPath
            );
          } else {
            let mensaje =
              this.sumario.sumarioEstado != "Finalizada"
                ? "La Actuacion Se Finalizo con Exito"
                : "La Actuacion Se reestablecion con Exito";
            this.finalizarCambioEstado(mensaje, iconModal, true, false, "/sumario");
          }
        }
      } catch (e) {
        let iconModal = { type: "pi pi-exclamation-circle", style: "font-size: 3em; color: red;" };
        if (activarProrroga) {
          this.finalizarCambioEstado("No se pudo activar la prórroga", iconModal, true);
        } else {
          let mensaje =
            this.sumario.sumarioEstado != "Finalizada"
              ? "La Actuacion No se pudo Finalizar"
              : "La Actuacion no pudo Reestablecerse";
          this.finalizarCambioEstado(mensaje, iconModal, true, true);
        }
      }
      this.loading = false;
    },
    finalizarCambioEstado(mensaje, iconModal, msjEnable = false, error = false, routerPush) {
      this.aceptarLabel = undefined;
      this.cancelarLabel = undefined;
      this.buttomCancelar = { buttom: false, action: false };
      this.buttomAceptar = { buttom: true, action: false, routerPush };
      this.iconModal = iconModal;
      this.err = error;
      this.mensaje = mensaje;
      this.msjEnable = msjEnable;
    },
    colorActive() {
      switch (this.sumario.sumarioEstado) {
        case "En Curso":
          return "success";
          break;
        case "Vencida":
          return "warning";
          break;
        case "Finalizada":
          return "danger";
          break;
      }
    },
    async deleteFile(file) {
      const id = file.ActuacionArchivoID;
      let optionsDelete = {
        url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/files/sumario/${id}`,
      };
      if (window.confirm("¿Seguro que desea Eliminar este archivo?")) {
        try {
          this.loading = true;

          await this.$rest.delete(optionsDelete);
          await this.filesQuery();

          this.RemoveFiles();
        } catch (e) {
          console.error(e);
          this.loading = false;
        }
      }
      this.sortByDate();
    },
    async setDiligencias() {
      this.filesAndDiligences = Array.isArray(this.sumario.diligencia)
        ? this.sumario.diligencia
        : []; //se agrega un setTimeout para que espere a que la api guarde la diligencia antes de pedirla otra vez
    },
    fechaCreacion(fecha) {
      let fechaAux = new Date(fecha); //la fecha viene en formato string por eso la convertimos
      let fechaCambioHorarioBd = new Date("2022-04-08 19:00:45"); //esta es la fecha y hora a partir de la cual se actualizo la zona horaria de la Api
      if (fechaAux < fechaCambioHorarioBd) {
        let fechaYhoraAux = fechaAux.getTime() - 180 * 60000; //le restamos 3 horas, por que la mayoria de ellas, se guardaron con 3 horas +, ya que en argentina estamos -3
        let FechayHoraCreacion = new Date(fechaYhoraAux); //volvemos a formato fecha

        let fechaCreacion = FechayHoraCreacion.toLocaleDateString("es-AR"); //extraemos la fecha
        let horaCreacion = FechayHoraCreacion.toLocaleTimeString("es-AR"); //extraemos la hora

        return fechaCreacion + " " + horaCreacion;
      } else {
        let fechaCreacion = fechaAux.toLocaleDateString("es-AR"); //extraemos la fecha
        let horaCreacion = fechaAux.toLocaleTimeString("es-AR"); //extraemos la hora
        return fechaCreacion + " " + horaCreacion;
      }
    },
    async deleteActuacion() {
      // console.log(JSON.stringify(this.sumario.id))
      if (window.confirm("¿Seguro que desea Eliminar esta Actuacion?")) {
        this.loading = true;
        this.$store.dispatch("sumarioList/loadingUpdate", true);

        let resp = await this.$store.dispatch("sumario/deleteActuacion", this.sumario.id);

        if (resp == true) {
          // console.log("se borro correctamente")
          await this.$store.dispatch("sumarioList/deleteActuaciones", this.sumario.id);

          //actualizamos las actuaciones antes de volver a ellas
          await this.$store.dispatch("sumarioList/resetActuaciones");
          this.$router.push({ path: `/sumario` });
        }
        this.loading = false;
      }
    },
    changeSelectorStatus() {
      this.selectorDisabled = false;
      this.displayConfirmation = false;
    },
    async btnEnvio() {
      if (this.selectorDisabled) {
        this.displayConfirmation = true;
      } else {
        // console.log(this.sumario.ActuacionUsuarioDependencia);
        // console.log(this.sumario ,(this.sumario.ActuacionUsuarioDependencia.find(e=>e.Fechahasta===null)).Numeroextracto)
        let datos = {
          UpdatePrevious: { DependenciaID: this.sumario.DependenciaID }, //enviamos los datos nescesarios para actualizar el registro anterior(en este caso solo nescesita el Id de la dependencia actual del sumario)
          LoadNew: {
            DependenciaID: this.dependencia.id,
            Numeroextracto: this.sumario.causaNumero
              ? this.sumario.causaNumero
              : this.sumario.ActuacionUsuarioDependencia.find((e) => e.Fechahasta === null)
                  .Numeroextracto,
          }, //enviamos el Id de la nueva Dependencia
        };

        this.isDisabled.btnEnviar = true;
        // console.log("el id que manda es: ", this.sumario.actuacionUsuarioDependenciaID)
        let request = await axios.post(
          `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/actuacion-usuario-dependencia/${this.sumario.actuacionUsuarioDependenciaID}`,
          datos,
          { withCredentials: true, params: { method: "own" } }
        );
        await this.$store.dispatch("sumarioList/get", { accion: "inicio" });

        if (request.data.error === "" && request.status === 200) {
          this.$router.push({ path: `/actuaciones/list` });
          alert(
            `Se envió la actuación a la dependencia ${this.dependencia.dependencia} correctamente`
          );
        }
      }
    },
    openConfirmation() {
      this.displayConfirmation = true;
    },
    closeConfirmation() {
      this.displayConfirmation = false;
    },
    goToAddDiligencia() {
      console.log(sumario);
      console.log(this);
      // $router.push('/sumario/denuncia/detalle/'+sumario.SumarioID+'/addDiligencia')
    },
    //El siguiente metodo habilita los botones relacionados a la actuacion
    authorizedUserActuacion(aplicaSobre = []) {
      let tiposUserAutorizados = aplicaSobre;
      let propietario = false;
      if (aplicaSobre.includes("SecretarioActual")) {
        //si el usuario logeado es el secretario actual de la actuacion entonces le permitimos editar/elimar
        propietario = this.sumario?.ActuacionUsuarioDependencia?.find((e) => {
          if (e.NombreUsuario == this.user.userdata.usuarioNombre && e.Fechahasta == null) {
            return true;
          }
        });
      }
      if (aplicaSobre.includes("Super User")) {
        // en caso de ser algo que solo los Super Usuarios puedan ver
        tiposUserAutorizados.push("Super Usuario de E3", "Super User", "User Tester");
      }
      // if(propietario || this.user.userdata.usuarioNombre == "SuperUserE3" || this.user.userdata.permisos.find( element => element.TipoUsuarioID == "SuperUser" || element.TipoUsuarioID == "UserTester" || element.TipoUsuarioID == "TipoUsuarioJefeDeDependencia" || element.TipoUsuarioID == "TipoUsuarioJefeDeSumario"))
      if (propietario || tiposUserAutorizados.includes(this.user.userdata.tipoUsuario)) {
        return true;
      } else {
        return false;
      }
    },
    //El siguiente metodo habilita los botones relacionados a las diligencias
    authorizedUserDiligencia(diligenciaID = null){
      let propietario = diligenciaID == this.user.uid ? true : false
      //si el usuario es el SuperUserE3 que pueda verlos
      //Y si es un Jefe de sumario tambien puede Editar/eliminar
      // console.log('this.user.userdata', this.user.userdata);
      if(
        propietario || 
        this.user.userdata.usuarioNombre == "SuperUserE3"|| 
        this.user.userdata.claseDeUsuario == "UsuarioFiscal"|| 
        this.user.userdata.permisos.find( 
          element => element.TipoUsuarioID == 'TipoUsuarioJefeDeSumario' || 
          element.TipoUsuarioID == 'UserTester' ||
          element.TipoUsuarioID == 'SuperUser'
        ) != undefined
      )
      {
        return true;
      }else {
        return false;
      }
    },
    showMo() {
      //!sumario.formType.includes('contravencional') || !sumario.formType.includes('Contravencional')
      // console.log(this.sumario?.formType)
      if (this.sumario.formType == null || this.sumario?.formType == undefined) {
        this.$rest.toast_open({
          message: "ERROR: No se pudo identificar el tipo de esta actuación",
          position: "top-right",
          type: "error",
          duration: 10000,
        });
      }
      if (this.sumario.formType.includes("contravencional")) {
        return false;
      } else if (this.sumario.formType.includes("Contravencional")) {
        return false;
      } else return true;
    },
    apellidoNombre(apellido, nombre) {
      var respuesta = "";
      //apellido y nombre pueden venir de realizar un "concat" en sumario.js, lo que implica que null puede ser tomado como un string
      respuesta =
        apellido == "null" || apellido == null || apellido == "Sin Apellido Especificado"
          ? ""
          : apellido;
      respuesta =
        nombre == "null" || nombre == null || nombre == "Sin Apellido Especificado"
          ? respuesta
          : respuesta == ""
          ? nombre
          : respuesta + " ," + nombre;
      return respuesta;
    },
    // names(apellido, nombre){
    //   let name
    //   let cont = 0
    //   if(apellido != null)
    //   {
    //     name = apellido
    //     cont ++;
    //   }
    //   if( nombre != null)
    //   {
    //     if(cont > 0 )
    //     {
    //       name = name +", "+ nombre
    //     }else
    //     {
    //       name = nombre
    //     }
    //   }
    //   return (name)
    // },
    async deleteDiligencia(diligenciaId) {
      if (window.confirm("¿Seguro que desea Eliminar esta diligencia?")) {
        this.loading = true;
        let resp = await this.$store.dispatch("sumario/deleteDiligencia", diligenciaId);
        if (resp == true) {
          // console.log("se borro correctamente")
          let indice = this.filesAndDiligences.findIndex(
            (element) => element.DiligenciaID == diligenciaId
          );
          this.filesAndDiligences.splice(indice, 1);
          // this.filesAndDiligencesByDate.splice(indice, 1)
        }
        this.loading = false;
      }
    },
    editDiligencia(diligenciaId) {
      this.changeNameInStore(this.formType);
      this.$router.push("/actuaciones/" + this.actuacionType + "/print/edit" + diligenciaId);
    },

    seeDiligencia(diligenciaId) {
      this.countPrint(diligenciaId);
      // console.log(diligenciaId)
      this.changeNameInStore(this.formType);
      this.$router.push("/actuaciones/" + this.actuacionType + "/print/" + diligenciaId);
    },
    async countPrint(DiligenciaID) {
      let fullDate = new Date();

      let date = `${fullDate.getFullYear()}-${
        fullDate.getMonth() + 1
      }-${fullDate.getDate()} ${fullDate.getHours()}:${fullDate.getMinutes()}:00`;

      // console.log("soy la fecha",date)
      // console.log("soy la fecha",fullDate)
      // console.log("soy el user",this.user)
      // console.log("soy el sumario",this.sumario.dependenciaPolicial)
      this.$store.dispatch("sumario/addCounterPrint", {
        DiligenciaID: DiligenciaID,
        ComisariaID: this.user.dependencia,
        Paginas: 1,
        FechaIniVig: date,
      });
    },
    fechaslash(data) {
      var fecha = new Date(data);
      return fecha.toLocaleDateString("es-AR");
    },
    setData(data) {
      for (let i in data) {
        this[i] = data[i];
      }
    },
    updateFiles(event) {
      if (!this.$offline.onlineMode || this.resumen.ActuacionID.search("offline-") == 0) {
        this.$rest.toast_open({
          message: "OFFLINE SIIS: No se pueden descargar o subir archivos en modo offline.",
          position: "top-right",
          type: "error",
          duration: 3000,
        });
        return;
      }
      this.formData = new FormData();
      event.files.map((file) => {
        if (
          file.type !== "application/pdf" &&
          file.type !== "image/jpeg" &&
          file.type !== "image/png"
        ) {
          this.err =
            "Al menos uno de los archivos es incompatible con los formatos permitidos (JPG, PNG y/o PDF)";
          this.RemoveFiles();
          this.$refs.FileUpload.clear();
          setTimeout(() => {
            this.err = false;
          }, 5000);
          return;
        }
        // console.log("this.formData.= ",this.formData)
        this.formData.append("userfile[]", file, file.name);
        // console.log("this.formData.= ",this.formData)
      });
    },
    RemoveFiles() {
      this.formData = null;
    },
    async myUploader() {
      if(
        this.user.userdata.tipoUsuario === "Dpto.Criminalistica" || 
        this.user.userdata.tipoUsuario === "Lab. Informatico Forense"
      ){
        this.emailBody = 'emailFileHtml';
        this.sendAllMails();
      }
      this.loading = true;
      try {
        if (!this.$offline.onlineMode || this.resumen.ActuacionID.search("offline-") == 0) {
          this.$rest.toast_open({
            message: "OFFLINE SIIS: No se pueden descargar o subir archivos en modo offline.",
            position: "top-right",
            type: "error",
            duration: 3000,
          });
          return;
        }
        // action="http://localhost/upload/do_upload"
        // `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sumario/upload`
        console.log("this.formData: ", this.formData);
        let optionsPost = {
          // url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/files/sumario?search={"ActuacionID": [{"operator": "=", "value": "${this.resumen.ActuacionID}"}]}`,
          url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/files/sumario?ActuacionID=${this.resumen.ActuacionID}`,

          formData: this.formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        await this.$rest.post(optionsPost);

        await this.filesQuery();
        // console.log("response = res.data.data", res)
        // this.response = res.data.data

        this.RemoveFiles();
        this.sortByDate();
      } catch (e) {
        console.error("err", e);
        this.err = "Error al subir archivos";
        setTimeout(() => {
          this.err = false;
        }, 5000);
        await this.filesQuery();
        this.RemoveFiles();
      }
    },
    processFiles(data) {
      //files from backend must be processed, because they come in nested arrays
      // console.log
      // console.log("FILES >>>>>>>>>>",data)
      this.filesAndDiligences = [];
      // let arrayFiles = []
      // data.map( (objFiles) => {
      //   objFiles.fileList.map( (file) => {
      //     arrayFiles.push(file)
      //   });
      // });
      // console.log("DILIGIGENCIAS >>>>>>>>>>", this.sumario.diligencia)
      // console.log(this.sumario.diligencia)
      // console.log("files", this.filesAndDiligences)
      for (let i = 0; i < this.sumario.diligencia.length; i++) {
        let diligence = { ...this.sumario.diligencia[i] };

        // diligence.doc.uploaded = true;
        this.filesAndDiligences.push(diligence);
      }
      for (let i = 0; i < data.length; i++) {
        if (data[i].OrderTable !== null) {
          data[i].OrderTablePrint = data[i].OrderTable;
        } else {
          data[i].OrderTablePrint = null;
        }
        let file = { file: true, ...data[i], OrderTablePrint: data[i].OrderTable };
        //   diligence.doc.uploaded = true;
        this.filesAndDiligences.push(file);
      }
      if (
        this.orderBy.nombre === "Impresión" &&
        this.filesAndDiligences.some((item) => item.OrderTablePrint !== null)
      ) {
        this.sortTheOrderPrintNulls();
      } else {
        this.filesAndDiligencesByDate = this.filesAndDiligences.sort(this.sortByDate);
      }
      this.files = data;
      // console.log("this.files", this.files)
    },
    async filesQuery(id = this.resumen.ActuacionID) {
      // console.log("this.resumen.ActuacionID", id, this.resumen.ActuacionID)
      try {
        // resp = await axios.get(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/api/getFiles?nroSumario=${this.resumen.numeroSumario}`, { withCredentials: true })
        this.loading = true;

        let resp;
        // resp = await axios.get(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sumario/${id}`, { withCredentials: true })
        let fdata = [];
        if (!this.$offline.onlineMode || this.resumen.ActuacionID.search("offline-") == 0) {
          this.$rest.toast_open({
            message: "OFFLINE SIIS: No se pueden descargar o subir archivos en modo offline.",
            position: "top-right",
            type: "info",
            duration: 1000,
          });
        } else {
          resp = await this.$rest.get({
            url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/files/sumario/?search={"ActuacionID": [{"operator": "=", "value": "${id}"}]}`,
          });
          fdata = resp.data.data;
        }
        await this.processFiles(fdata);
        this.loading = false;
        // console.log("la api dice que_: "+ JSON.stringify(resp.data.data[0]))
        //  console.log("response files >>>>>>>>",resp)
        // this.response = resp.data.data
        // console.log("response >>>>>>>>"+JSON.stringify(this.response[0].Denunciantes))
      } catch (error) {
        console.log("filesQuery err", error);
      }
    },
    // async deleteFile(file){
    //   let resp
    //   try {
    //     resp = await axios.delete(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/api/uploads/nroSumario=${this.resumen.numeroSumario}/nombreArchivo=${file}`, { withCredentials: true })
    //     this.filesQuery()
    //   } catch (error) {
    //     console.log('err', error);
    //   }
    // },
    $_TemplateForm_update(field, value) {
      this.$emit("updateValue", field, value);
    },
    // elimina del array
    $_TemplateParentForm_delete(id) {
      let arrId = id;
      this.arrId = null;
      this.$store.dispatch("sumario/deleteDiligencia", arrId);
    },
    viewFile(file) {
      return `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/files/sumario/${file.ActuacionArchivoID}?view=true`;
      console.log("file.ActuacionArchivoID: ", file.ActuacionArchivoID);
    },
    async updateSumario(id) {
      let response = await this.$store.dispatch("sumario/setSumario", id);
      // console.log(response)
      if (!response) {
        // this.$router.push('/sumario')
        // this.VueCli.$toast.open({
        //   message: 'No se encontró el sumario con ese ID',
        //   type: 'is-danger',
        //   duration: 3000
        // })
      }
    },
    toastSuccess() {
      this.$rest.toast_open({
        message: "Datos guardados",
        position: "top-right",
        type: "success",
        duration: 3000,
      });
    },
    inputDependencia(event) {
      let value = this.dependenciaList.filter(
        (item) => item.dependencia.toLowerCase().search(this.dataDependencia.toLowerCase()) >= 0
      );
      this.dependencia = value[0];
    },
    filterDependencia(event) {
      this.filteredDependencia = this.dependenciaList.filter(
        (item) => item.dependencia.toLowerCase().search(event.query.toLowerCase()) >= 0
      );
    },
    onRowReorder(event) {
      this.filesAndDiligences = event.value;
    },
    sortByDate(a, b) {
      // if (a.DiligenciaID.search("offline-") === 0) return 1
      //Selecciono el tipo de ID por que si se trata de un archivo accedera a ActuacionArchivoID y DiligenciaID no existira; lo mismo a la inversa
      let aFiv;
      let bFiv;

      if (!a.Fechainivig || a.Fechainivig === undefined) {
        aFiv = "1999-01-01 00:00:00";
      } else {
        aFiv = a.Fechainivig;
      }
      if (!b.Fechainivig || b.Fechainivig === undefined) {
        bFiv = "1999-01-01 00:00:00";
      } else {
        bFiv = b.Fechainivig;
      }
      let id = a["DiligenciaID"] || a["ActuacionArchivoID"];
      // console.log("id= ", id);
      // console.log("fiv= ", aFiv);
      // console.log("fiv= ", bFiv);
      if (id.search("offline-") === 0) return 1;
      var reA = /[^a-zA-Z]/g;
      var reN = /[^0-9]/g;
      // console.log("1");
      var aA = aFiv.replace(reA, "");
      var bA = bFiv.replace(reA, "");
      // console.log("2");
      if (aA === bA) {
        var aN = parseInt(aFiv.replace(reN, ""), 10);
        var bN = parseInt(bFiv.replace(reN, ""), 10);
        return aN === bN ? 0 : aN < bN ? 1 : -1;
      } else {
        return aA < bA ? 1 : -1;
      }
    },
    sortByOrderTablePrint(a, b) {
      var reA = /[^a-zA-Z]/g;
      var reN = /[^0-9]/g;
      var aA = a.OrderTablePrint.replace(reA, "");
      var bA = b.OrderTablePrint.replace(reA, "");
      if (aA === bA) {
        var aN = parseInt(a.OrderTablePrint.replace(reN, ""), 10);
        var bN = parseInt(b.OrderTablePrint.replace(reN, ""), 10);
        return aN === bN ? 0 : bN < aN ? 1 : -1;
      } else {
        return bA < aA ? 1 : -1;
      }
    },
    filterOrder(event) {
      this.filteredOrder = this.orderList.filter(
        (item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0
      );
    },
    async confirm() {
      let data = {
        NuevoEstado: "Finalizada",
        Esclarecido: true, //Si fue esclarecido solo hay que enviarle true
      };
      this.loading = true;
      try {
        let request = await axios.put(
          `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/estado-actuacion/${this.sumario.id}`,
          data,
          { withCredentials: true, params: { method: "own" } }
        );
        if (request.data.error === "") {
          let iconModal = {
            type: "pi pi-check-circle",
            style: "font-size: 3em; color: rgb(89, 150, 29);",
          };
          let mensaje = "La Actuacion Se Finalizo con Exito";
          this.finalizarCambioEstado(mensaje, iconModal, true, false, "/actuaciones/list");
        }
      } catch (e) {
        let iconModal = { type: "pi pi-exclamation-circle", style: "font-size: 3em; color: red;" };
        let mensaje = "La Actuacion No se pudo Finalizar";
        this.finalizarCambioEstado(mensaje, iconModal, true, true);
      }
      this.loading = false;
    },
    updateOrderTableDataBase() {
      let i = 1;
      this.filesAndDiligences.map((item) => {
        if (item.file === undefined) {
          let payload = { OrderTablePrint: i, diligenciaID: item.DiligenciaID, name: item.Nombre };
          console.log(payload);
          this.filesAndDiligences[i - 1].OrderTablePrint = i.toString();
          this.$store.dispatch("sumario/editOrderTablePrintFromDiligencia", payload);
          i++;
        }
        if (item.file === true) {
          let payload = {
            OrderTablePrint: i,
            ActuacionArchivoID: item.ActuacionArchivoID,
            name: item.Filename,
            FechaCreacion: item.FechaCreacion,
            Extension: item.Extension,
          };
          console.log(i, item.Filename, item.ActuacionArchivoID);
          console.log(payload);
          this.filesAndDiligences[i - 1].OrderTablePrint = i.toString();
          this.$store.dispatch("sumario/editOrderTableForFiles", payload);
          i++;
        }
      });
    },
    sortTheOrderPrintNulls() {
      let i = 1;
      this.filesAndDiligencesByDate = this.filesAndDiligences.sort(this.sortByDate).reverse();
      let amountOfNullOrderPrints = this.filesAndDiligencesByDate.filter(
        (fileOrDiligence) => fileOrDiligence.OrderTablePrint === null
      ).length;
      const newOrdenPrint = this.filesAndDiligencesByDate.length - amountOfNullOrderPrints;
      this.filesAndDiligencesByDate.map((fileOrDiligence) => {
        if (fileOrDiligence.OrderTablePrint === null) {
          fileOrDiligence.OrderTablePrint = (newOrdenPrint + i).toString();
          i++;
        }
      });
      this.filesAndDiligences = this.filesAndDiligencesByDate.sort(this.sortByOrderTablePrint);
    },
    async updateSumario(id) {
      await this.$store.dispatch("sumario/setSumario", id);
    },
    toastSuccess() {
      this.$rest.toast_open({
        message: "Datos guardados",
        position: "top-right",
        type: "success",
        duration: 3000,
      });
    },
    findMin(array) {
      let min;
      array.map((e) => {
        if (!min) {
          min = e;
        }
        if (min > e) {
          min = e;
        }
      });
      return min;
    },
    openModalTemplate(type) {
      this.$refs[type].open();
    },
    closeModalTemplate(type) {
      this.$refs[type].close();
    },
    verifyAmountOfDiligences() {
      if (this.sumario.diligencia.length >= 3) {
        this.openModalTemplate("ModalRefConfirm");
      } else {
        this.openModalTemplate("ModalRefInform");
      }
    },
    async goToActuationEditMode() {
      await this.changeNameInStore(this.formType);
      this.$store.dispatch("editMode/prepareActuationForEdition", this.sumario);
      this.$store.dispatch("sumario/setEditMode", true);
      this.$router.push("/actuaciones/edit/" + this.actuacionType + "/initial");
    },
    changeNameInStore(formType) {
      const viewConfig = actuacionesConfig.find((config) => config.path === "/actuaciones/view/");
      if (viewConfig && viewConfig.actuaciones) {
        const foundActuacion = viewConfig.actuaciones.find(
          (actuacion) => actuacion.nameInStore === formType
        );

        if (foundActuacion) {
          this.actuacionType = foundActuacion.pathName;
        } else {
          console.log(
            "No se encontró ninguna coincidencia para el formType proporcionado en /actuaciones/view/."
          );
          this.actuacionType = null;
        }
      } else {
        console.log("No se encontró la configuración para /actuaciones/view/.");
      }
    },
    goToAgregarDiligencia() {
      this.changeNameInStore(this.formType);
      if (this.actuacionType) {
        this.$router.push(
          `/actuaciones/new/${this.actuacionType}/${this.sumario.id}/addDiligencia`
        );
      } else {
        console.log("No se pudo encontrar un pathName para formType:", this.formType);
      }
    },
    async sendMailToDependency(slotProps) {
      this.indexEmailButtonDisabled = slotProps?.index;
      let emailAddressee = await this.getDependencyEmailFromStore();
      this.sendMail(emailAddressee);
      this.indexEmailButtonDisabled = null;
    },
    generateEmailSubject(){
      if(this.user.userdata.tipoUsuario === "Dpto.Criminalistica"){
        return this.sumario.causaNumero + " - PERICIA CRIMINALÍSTICA";
      }
      if(this.user.userdata.tipoUsuario === "Lab. Informatico Forense"){
        return this.sumario.causaNumero + " - INFORME TÉCNICO LABORATORIO INFORMÁTICO FORENSE";
      }
      return ' ';
    },
    async getDependencyEmailFromStore(){
      await this.$store.dispatch('sumario/getDependencyEmail', this.sumario.dependenciaID);
      return this.sumario.dependencyEmail;
    },
    determineEmailButtonVisibility(buttonData){
      let isFile = buttonData.file;
      if(!isFile) return false;
      switch (buttonData.TipoUsuario) {
        case 'Dpto.Criminalistica':
          return this.user.userdata.tipoUsuario === 'Dpto.Criminalistica' ? true : false;
        case 'Lab. Informatico Forense':
          return this.user.userdata.tipoUsuario === 'Lab. Informatico Forense' ? true : false;
        case '':
          return false;
        default:
          return false;
      }
    },
    async getDefaultEvidence(){
      this.evidence = '';
      let resp = await this.$rest.get({url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/actuacion-evidencia?search={"ActuacionID":[{"operator":"=","value":"${this.resumen.ActuacionID}"}]}`});
      if(resp.data.data[0]?.Url !== undefined){
        this.evidence = this.ensureHTTP(resp.data.data[0]?.Url).replace(/\s+/g, ''); //Eliminando espacios en blanco
      }
    },
    saveEvidenceAndSendEmail() {
      this.changeSaveButtonToLoadingMode('loadText');
      this.saveEvidenceData()
      .then(() => {
        this.sendMailToDependency();
        this.emailBody = 'emailEvidenceHtml';
        this.changeSaveButtonToLoadingMode('defaultText');
        this.$refs.ModalRefEvidence.close();
      })
      .catch(() => {
        this.$toast.open({ message: "Ocurrió un error", position: 'top-right', type: 'error', duration: 5000 });
        this.changeSaveButtonToLoadingMode('defaultText');
      })
    },
    changeSaveButtonToLoadingMode(textType){
      this.$refs.ModalRefEvidence.$refs.saveButtonRef.$el.disabled = true;
      const texts = {
        loadText: '<i class="pi pi-spin pi-spinner" style="margin-right: .5rem;"></i> Cargando...',
        defaultText: '<span class="pi pi-check p-button-icon p-button-icon-left"></span> Guardar'
      }
      this.$refs.ModalRefEvidence.$refs.saveButtonRef.$el.innerHTML = texts[textType];
      if(textType === 'defaultText')
        this.$refs.ModalRefEvidence.$refs.saveButtonRef.$el.disabled = false;
    },
    async saveEvidenceData() {
      return new Promise(async (resolve, reject) => {
        try {
          const formData = { "ActuacionID": this.resumen.ActuacionID, Url: this.evidence };
          const optionsPost = {
            url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/actuacion-evidencia`, formData: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
          };
          await this.$rest.post(optionsPost);
          this.$toast.open({ message: "La evidencia fue guardada con éxito", position: "top-right", type: "success", duration: 5000 });
          resolve();
        } catch (e) {
          this.$toast.open({ message: "Error: No se pudo guardar la evidencia", position: 'top-right', type: 'error', duration: 5000 });
          console.error('Error con evidencia: ', e);
          reject();
        }
      });
    },
    changeSaveButtonToLoadingMode(textType){
      this.$refs.ModalRefEvidence.$refs.saveButtonRef.$el.disabled = true;
      const texts = {
        loadText: '<i class="pi pi-spin pi-spinner" style="margin-right: .5rem;"></i> Cargando...',
        defaultText: '<span class="pi pi-check p-button-icon p-button-icon-left"></span> Guardar'
      }
      this.$refs.ModalRefEvidence.$refs.saveButtonRef.$el.innerHTML = texts[textType];
      if(textType === 'defaultText')
        this.$refs.ModalRefEvidence.$refs.saveButtonRef.$el.disabled = false;
    },
    async getDefaultEvidence(){
      let resp = await this.$rest.get({url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/actuacion-evidencia?search={"ActuacionID":[{"operator":"=","value":"${this.resumen.ActuacionID}"}]}`});
      if(resp.data.data[0]?.Url !== undefined){
        this.evidence = resp.data.data[0]?.Url;
      }
    },
    showEmailSucessMessage() {
      this.$toast.open({
        message: "El e-mail fue enviado con éxito",
        position: "top-right",
        type: "success",
        duration: 5000,
      });
    },
    copyToClipboard(){
      if (navigator?.clipboard) {
        this.copyToClipboardWithNavigator();
      } else {
        this.copyToClipboardWithExecCommand();
      }
    },
    ensureHTTP(url) {
      if (!url.includes("https://") && !url.includes("http://")) {
        return "https://" + url;
      }
      return url;
    },
    showCopyToClipboardToast(){
      this.$toast.open({
        message: "Url copiada en el portapapeles",
        position: "top-right",
        type: "success",
        duration: 5000,
      });
    },
    copyToClipboardWithExecCommand(){
      var textArea = document.createElement("textarea");
      textArea.value = this.evidence;
      textArea.style.position = "absolute";
      textArea.style.opacity = "0";
      textArea.style.pointerEvents = "none";
      textArea.style.zIndex = "-1";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'exitoso' : 'fallido';
        console.log('Copia de texto fue ' + msg);
        if(msg === 'exitoso') this.showCopyToClipboardToast();
      } catch (err) {
        console.error('Error al intentar copiar el texto: ', err);
      }
    },
    copyToClipboardWithNavigator(){
      navigator.clipboard.writeText(this.evidence).then( () => {
        console.log('Url copiada en el portapapeles');
        this.showCopyToClipboardToast();
      }).catch(function(err) {
        console.error('Error al copiar el texto: ', err);
      });
    },
    showEmailErrorMessage() {
      this.$toast.open({
      message: `
        No se puede enviar el email. Comuníquese con la 
        <a 
          href="https://api.whatsapp.com/send/?phone=542644446909&text=Hola%20estoy%20utilizando%20el%20SIIS,%20no%20puedo%20enviar%20correo%20electr%C3%B3nico%20de%20" 
          target="_blank"
          style="text-decoration: none; color: inherit;"
        >
          <u>Mesa de Ayuda.</u>
        </a>
      `,
      position: 'top-right',
      type: 'error',
      duration: 0,
      });
    },
    getDate(obj) {
      const dateStr = obj.Fechainivig || obj.FechaCreacion;
      if (dateStr) {
        return this.unifyDateFormat(dateStr);
      }
      return 'Invalid Date';
    },
    unifyDateFormat(dateStr) {
      const format1 = /^\d{1,2}\/\d{1,2}\/\d{4} \d{2}:\d{2}:\d{2}$/;
      const format2 = /^\d{1,2}-\d{1,2}-\d{2} \d{2}:\d{2}:\d{2}$/;
      const format3 = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
      if (format1.test(dateStr)) {
        const [date, time] = dateStr.split(' ');
        const [day, month, year] = date.split('/');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${time}`;
      } else if (format2.test(dateStr)) {
        const [date, time] = dateStr.split(' ');
        const [day, month, year] = date.split('-');
        return `20${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${time}`;
      } else if (format3.test(dateStr)) {
        return dateStr.replace(' ', 'T');
      } else {
        return 'Invalid Date';
      }
    }, 
    sortByDate() {
      this.filesAndDiligencesByDate = this.filesAndDiligences.sort((a, b) => {
        const dateA = new Date(this.getDate(a));
        const dateB = new Date(this.getDate(b));
        return  dateB - dateA;
      });
    },
    sendMailToUFIAndMpftesting() {
      let translatedFormTypeName = generalFunctions.translateNameFromFormType(this.formType);
      let emailUFI = this.viewData[translatedFormTypeName].emailUFI;
      if(emailUFI === undefined) return;
      if(
        !process.env.VUE_APP_BACKEND_ENVIRONMENT.includes('dev.electro3.com.ar') &&  
        !process.env.VUE_APP_BACKEND_ENVIRONMENT.includes('siistesting.electro3.com.ar')
      ){
        this.sendMail(emailUFI);
        this.sendMail('mpftesting.24@gmail.com');
      }
    },
    async sendMail(emailAddressee) {
      try {
        if(emailAddressee === ''){
          throw new Error("No se puede enviar el email porque falta el destinatario.");
        }
        let formData = {
          Remitente: "siisadmin@policia.sanjuan.gob.ar", Destinatario: emailAddressee, Asunto: this.generateEmailSubject(),
          Mensaje: this[this.emailBody],
        };
        let optionsPost = {
          url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/correo-electronico`,
          formData: formData, headers: { "Content-Type": "multipart/form-data" },
        };
        await this.$rest.post(optionsPost);
        this.showEmailSucessMessage();
      } catch (error) {
        console.error('Error al enviar el email:', error);
        this.showEmailErrorMessage();
      }
    },
    async sendAllMails(slotProps) {
      await this.sendMailToDependency(slotProps);
      await this.sendMailToUFIAndMpftesting();
    },
    determineActionButtonsVisibility(item){
      let itemIsVisible = true;
      if(item.data.TipoUsuario === 'Dpto.Criminalistica'){
        itemIsVisible = this.$rest.esTipoUsuario(
          [
            'Dpto.Criminalistica', 'Lab. Informatico Forense', 'User Tester', 'Super User', 'Ayudante Fiscal', 'Fiscal',
            'Fiscal General','Supervisor', 'Fiscal General', 'Administrativo', 'Policia Investigador'
          ], this.$store?.state?.user
        )
      }
      return itemIsVisible;
    }
  },
};
</script>

<style scoped>
.danger__text {
  color: #ff0000;
}
.confirmation-content {
  padding: 10px;
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectorOption {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.hiddenSelector {
  visibility: hidden;
}
.enabledSelector {
  margin-right: 15px;
  width: 360px;
}
.vertical-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}
.menuprint {
  border-bottom: 1px solid #6c757d;
}
ul {
  margin: 0 0 10px;
  list-style: none;
  padding: 0;
}
ul li {
  list-style: none;
  margin: 0 0 5px;
  padding: auto 0;
}
.detalleSumario h4 {
  margin: 0 0 5px;
}
.detalleSumario p {
  margin: 0 0 10px;
}
.p-button {
  height: 40px;
}
.p-fileupload-buttonbar {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.file-title {
  margin-top: 40px;
  margin-bottom: 20px;
}
.negative-mt {
  margin-top: -15px;
}
.input-order {
  min-width: 250px;
  margin-top: -17px;
}
.edit-pencil {
  margin-top: -10px;
  margin-left: 5px;
}
.articulo__nombre {
  width: 82%;
}
.listaArticulos {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  line-height: 0.9;
  font-size: smaller;
}
.containerArticulos {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}
.btn {
  width: 18%;
}
>>> .p-dialog-content {
  overflow-y: visible;
}
.mt-buttons {
  margin-top: 30px;
}
.preloader {
  display: flex;
  justify-content: center;
  padding: 0;
  height: 45px;
  width: 45px;
  margin-bottom: 25px;
}
</style>@/router/RoutesConfig/actuacionesConfig/actuacionesConfig
